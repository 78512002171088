import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";

import React, { Suspense, lazy } from "react";

import Navbar from "./Navbar/Navbar";
import Footer from "./Footer/Footer";

const Home = lazy(() => import("./pages/Home"));
const Contact = lazy(() => import("./pages/Contact"));
const CookiesPolicy = lazy(() => import("./pages/CookiesPolicy"));
// Work Load
const OurWork = lazy(() => import("./pages/OurWork"));
const Integrated_experience_oman = lazy(() =>
  import("./pages/OurWork/integrated/experience-oman/experience-oman")
);
const Integrated_traveller_oasis = lazy(() =>
  import("./pages/OurWork/integrated/traveller-oasis/traveller-oasis")
);
const Integrated_oman_arab_bank = lazy(() =>
  import("./pages/OurWork/integrated/oman-arab-bank/oman-arab-bank")
);
const Integrated_oman_broadband = lazy(() =>
  import("./pages/OurWork/integrated/oman-broadband/oman-broadband")
);
const Integrated_thawani = lazy(() =>
  import("./pages/OurWork/integrated/thawani/thawani")
);
const Digital_traveller_oasis = lazy(() =>
  import("./pages/OurWork/digital/traveller-oasis/traveller-oasis")
);
const Digital_thawani = lazy(() =>
  import("./pages/OurWork/digital/thawani/thawani")
);
const Digital_OmanEvents = lazy(() =>
  import("./pages/OurWork/digital/oman-events/oman-events")
);
const Digital_oman_arab_bank = lazy(() =>
  import("./pages/OurWork/digital/oman-arab-bank/oman-arab-bank")
);
const Digital_experience_oman = lazy(() =>
  import("./pages/OurWork/digital/experience-oman/experience-oman")
);
const Branding_oman_air = lazy(() =>
  import("./pages/OurWork/branding/oman-air/oman-air")
);
const Branding_oman_broadband = lazy(() =>
  import("./pages/OurWork/branding/oman-broadband/oman-broadband")
);
const Branding_oman_Tv = lazy(() =>
  import("./pages/OurWork/branding/oman-tv/oman-tv")
);
const Branding_thawani = lazy(() =>
  import("./pages/OurWork/branding/thawani/thawani")
);
const Branding_national_museum = lazy(() =>
  import("./pages/OurWork/branding/national-museum/national-museum")
);
const Adv_SarayaBandarJissah = lazy(() =>
  import(
    "./pages/OurWork/advertising/saraya-bandar-jissah/saraya-bandar-jissah"
  )
);
const Adv_BankMuscat = lazy(() =>
  import("./pages/OurWork/advertising/bank-muscat/bank-muscat")
);
const Adv_OmaArabBank = lazy(() =>
  import("./pages/OurWork/advertising/oman-arab-bank/oman-arab-bank")
);
const Adv_Orpic = lazy(() => import("./pages/OurWork/advertising/orpic/orpic"));
const Adv_Omantel = lazy(() =>
  import("./pages/OurWork/advertising/omantel/omantel")
);
const Pr_omanarabbankandthawani = lazy(() =>
  import(
    "./pages/OurWork/pr/oman-arab-bank-and-thawani/oman-arab-bank-and-thawani"
  )
);
const Pr_museumofillusions = lazy(() =>
  import("./pages/OurWork/pr/museum-of-illusions/museum-of-illusions")
);
const Pr_gallopsofoman = lazy(() =>
  import("./pages/OurWork/pr/gallops-of-oman/gallops-of-oman")
);
const Pr_fz = lazy(() => import("./pages/OurWork/pr/fz/fz"));
const Pr_dhofarautomotive = lazy(() =>
  import("./pages/OurWork/pr/dhofar-automotive/dhofar-automotive")
);
const Eve_omatel = lazy(() => import("./pages/OurWork/events/omantel/omantel"));
const Eve_omanarabbankandthawani = lazy(() =>
  import(
    "./pages/OurWork/events/oman-arab-bank-and-thawani/oman-arab-bank-and-thawani"
  )
);
const Eve_dhofarautomotive = lazy(() =>
  import("./pages/OurWork/events/dhofar-automotive/dhofar-automotive")
);
const Eve_travelleroasis = lazy(() =>
  import("./pages/OurWork/events/traveller-oasis/traveller-oasis")
);
const Eve_zubair50thanniversary = lazy(() =>
  import(
    "./pages/OurWork/events/zubair-50th-anniversary/zubair-50th-anniversary"
  )
);

const NotFound = lazy(() => import("./pages/404"));

//
const People = lazy(() => import("./pages/People"));
const PrivacyPolicy = lazy(() => import("./pages/PrivacyPolicy"));
const Services = lazy(() => import("./pages/Services"));
const Story = lazy(() => import("./pages/Story"));
const TermsAndConditions = lazy(() => import("./pages/TermsAndConditions"));
const WorkWithUs = lazy(() => import("./pages/WorkWithUs"));

function App() {
  return (
    <>
      <Navbar />
      <div className="container body">
        <Suspense fallback={<div className="loading loading-lg"></div>}>
          <Routes>
            <Route path="/" element={<Navigate to="/en/" />} />
            <Route path="/en" element={<Home />} />
            <Route path="/en/get-in-touch" element={<Contact />} />
            <Route path="/en/cookies-policy" element={<CookiesPolicy />} />
            <Route path="/en/our-work" element={<OurWork />} />
            {/* Work Path */}
            <Route
              path="/en/our-work/integrated/experience-oman"
              element={<Integrated_experience_oman />}
            />
            <Route
              path="/en/our-work/integrated/traveller-oasis"
              element={<Integrated_traveller_oasis />}
            />
            <Route
              path="/en/our-work/integrated/oman-arab-bank"
              element={<Integrated_oman_arab_bank />}
            />
            <Route
              path="/en/our-work/integrated/oman-broadband"
              element={<Integrated_oman_broadband />}
            />
            <Route
              path="/en/our-work/integrated/thawani"
              element={<Integrated_thawani />}
            />
            <Route
              path="/en/our-work/digital/traveller-oasis"
              element={<Digital_traveller_oasis />}
            />
            <Route
              path="/en/our-work/digital/thawani"
              element={<Digital_thawani />}
            />
            <Route
              path="/en/our-work/digital/oman-events"
              element={<Digital_OmanEvents />}
            />
            <Route
              path="/en/our-work/digital/oman-arab-bank"
              element={<Digital_oman_arab_bank />}
            />
            <Route
              path="/en/our-work/digital/experience-oman"
              element={<Digital_experience_oman />}
            />
            <Route
              path="/en/our-work/branding/oman-air"
              element={<Branding_oman_air />}
            />
            <Route
              path="/en/our-work/branding/oman-broadband"
              element={<Branding_oman_broadband />}
            />
            <Route
              path="/en/our-work/branding/oman-tv"
              element={<Branding_oman_Tv />}
            />
            <Route
              path="/en/our-work/branding/thawani"
              element={<Branding_thawani />}
            />
            <Route
              path="/en/our-work/branding/national-museum"
              element={<Branding_national_museum />}
            />
            <Route
              path="/en/our-work/advertising/saraya-bandar-jissah"
              element={<Adv_SarayaBandarJissah />}
            />
            <Route
              path="/en/our-work/advertising/bank-muscat"
              element={<Adv_BankMuscat />}
            />
            <Route
              path="/en/our-work/advertising/oman-arab-bank"
              element={<Adv_OmaArabBank />}
            />
            <Route
              path="/en/our-work/advertising/orpic"
              element={<Adv_Orpic />}
            />
            <Route
              path="/en/our-work/advertising/omantel"
              element={<Adv_Omantel />}
            />
            <Route
              path="/en/our-work/pr/oman-arab-bank-and-thawani"
              element={<Pr_omanarabbankandthawani />}
            />
            <Route
              path="/en/our-work/pr/museum-of-illusions"
              element={<Pr_museumofillusions />}
            />
            <Route
              path="/en/our-work/pr/gallops-of-oman"
              element={<Pr_gallopsofoman />}
            />
            <Route path="/en/our-work/pr/fz" element={<Pr_fz />} />
            <Route
              path="/en/our-work/pr/dhofar-automotive"
              element={<Pr_dhofarautomotive />}
            />
            <Route
              path="/en/our-work/events/omantel"
              element={<Eve_omatel />}
            />
            <Route
              path="/en/our-work/events/oman-arab-bank-and-thawani"
              element={<Eve_omanarabbankandthawani />}
            />
            <Route
              path="/en/our-work/events/dhofar-automotive"
              element={<Eve_dhofarautomotive />}
            />
            <Route
              path="/en/our-work/events/traveller-oasis"
              element={<Eve_travelleroasis />}
            />
            <Route
              path="/en/our-work/events/zubair-50th-anniversary"
              element={<Eve_zubair50thanniversary />}
            />
            {/*  */}
            <Route path="/en/people" element={<People />} />
            <Route path="/en/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/en/services" element={<Services />} />
            <Route path="/en/story" element={<Story />} />
            <Route
              path="/en/terms-and-conditions"
              element={<TermsAndConditions />}
            />
            <Route path="/en/work-with-us" element={<WorkWithUs />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </Suspense>
      </div>
      <Footer />
    </>
  );
}

export default App;
