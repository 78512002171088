import React, { useState, useRef } from "react";
import emailjs from "@emailjs/browser";

import "./Form.css";
import Zeenah from "../../assets/zeenah_tm.svg";
import ZeenahW from "../../assets/zeenah_tm_white.svg";

import { Notyf } from "notyf";
import "notyf/notyf.min.css"; // for React, Vue and Svelte
// Create an instance of Notyf
const notyf = new Notyf();

export const Form = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_k84l1lh",
        "template_boz3apb",
        form.current,
        "xdwEjR3-3vc5tE3Nm"
      )
      .then(
        (result) => {
          console.log(result.text);
          console.log(result);

          notyf.success("Thank you will get back to you as soon as possible.");
          setTimeout(function () {
            window.location.reload(false);
          }, 3000);
        },
        (error) => {
          console.log(error.text);
          notyf.error(error.text);
        }
      );
  };

  return (
    <>
      <a href="#get-in-touch" className="Link">
        E: hello[at]zeenah.com
      </a>

      <div className="modal modal-sm" id="get-in-touch">
        <a className="modal-overlay" href="#close" aria-label="Close"></a>
        <div className="modal-container" role="document">
          <div className="modal-body">
            <div className="content">
              <picture>
                <source srcSet={ZeenahW} media="(prefers-color-scheme: dark)" />
                <img
                  className="PopImg"
                  fetchpriority="low"
                  height="180"
                  width="70"
                  alt="Zeenah | Integrated Marketing Agency Oman | web design agency Oman"
                  title="Zeenah | Integrated Marketing Agency Oman | web design agency Oman"
                  src={Zeenah}
                />
              </picture>

              <form className="homeForm" ref={form} onSubmit={sendEmail}>
                <label htmlFor="FullName">Full Name:</label>
                <input type="text" id="FullName" name="FullName" required />
                <label htmlFor="Email">Email:</label>
                <input type="email" id="Email" name="Email" required />
                <label htmlFor="MobileNumber">Mobile Number:</label>
                <input
                  type="number"
                  id="MobileNumber"
                  name="MobileNumber"
                  required
                />
                <label htmlFor="Message">Message:</label>
                <textarea
                  id="Message"
                  name="Message"
                  rows="4"
                  cols="50"
                  required
                ></textarea>
                <input className="Submit" type="submit" value="Send" />
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Form;
